import { mdiCheckboxBlankCircleOutline } from '@mdi/js';

const themeConfig = {
	app: {
		name: 'StreamStickers',
		logo: require('@/assets/images/logo.png'),
		isDark: true,
		isRtl: false,
		contentLayoutNav: 'vertical', // vertical, horizontal
		routeTransition: 'scroll-x-transition', // https://vuetifyjs.com/en/styles/transitions/#api
		// ! `semi-dark` isn't available for horizontal nav menu
		skinVariant: 'default', // default, bordered, semi-dark
		contentWidth: 'boxed',
	},
	menu: {
		isMenuHidden: false,
		isVerticalNavMini: false,
		verticalMenuAccordion: true,
		groupChildIcon: mdiCheckboxBlankCircleOutline,
		horizontalNavMenuGroupOpenOnHover: true,
	},
	appBar: {
		/*
    ! In Content Layout Horizontal Nav type `hidden` value won't work
    ! In Content Layout Horizontal Nav type value of `type` will affect both Appbar and Horizontal Navigation Menu
    */
		type: 'fixed', // fixed, static, hidden
		isBlurred: true,
	},
	footer: {
		type: 'static', // fixed, static, hidden
	},
	themes: {
		light: {
			primary: '#9155FD',
			accent: '#AB49E8',
			secondary: '#6740CD',
			success: '#56CA00',
			info: '#16B1FF',
			blueWaves: '#51f2f7',
			warning: '#8878e5',
			error: '#FF4C51',
			white: '#FFFFFF',
			purple: '#2a1953',
			grey: '#6f56b0',
			pink: '#e7318d',
			red: '#E6346F',
			textColor: '#000000',
			waveColor: '#52ffff',
			lightpurple: '#472B90',
			lightenPurple: '#55408F',
			blue: '#6740CD',
			black: '#000000',
			cardBg: '#3e2580',
			offWhite: '#d9d6ee',
			boldPurple: '#6340bf',
			shinyPurple: '#9b7bf1',
			purpleDivider: '#6740cd',
			disabledForm: '#9973fb',
			tooltipBg: '#8e45e3',
			purpleCard: '#532580'
		},
		dark: {
			primary: '#724BD8',
			accent: '#AB49E8',
			secondary: '#6740CD',
			success: '#56CA00',
			info: '#16B1FF',
			warning: '#8878e5',
			error: '#FF4C51',
			white: '#FFFFFF',
			fieldBack: '#382274',
			fieldFore: '#987be6',
			pinkItem: '#E8318E',
			blueWaves: '#51f2f7',
			purple: '#2a1953',
			grey: '#6f56b0',
			pink: '#e7318d',
			red: '#E6346F',
			textColor: '#FFFFFF',
			waveColor: '#52ffff',
			lightpurple: '#472B90',
			lightenPurple: '#55408F',
			blue: '#6740CD',
			black: '#000000',
			cardBg: '#3e2580',
			offWhite: '#d9d6ee',
			boldPurple: '#6340bf',
			shinyPurple: '#9b7bf1',
			purpleDivider: '#6740cd',
			disabledForm: '#9973fb',
			tooltipBg: '#8e45e3',
			purpleCard: '#532580'
		},
	},
};

export default themeConfig;
