import moment from 'moment';

const isNumber = value => {
	return value != undefined && value != null && !isNaN(value);
};

const isArray = value => {
	return value != undefined && value != null && Array.isArray(value);
};

const dataUrlToBlob = function (dataURI) {
	var byteString = atob(dataURI.split(',')[1]);
	var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
	var ab = new ArrayBuffer(byteString.length);
	var ia = new Uint8Array(ab);
	for (var i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}

	var blob = new Blob([ab], { type: mimeString });
	return blob;
};

const getBitIcon = value => {
	const bits = Number(value);

	if (bits <= 999) {
		return require('@/assets/icons/bits/100.png');
	}
	if (bits > 999 && bits <= 4999) {
		return require('@/assets/icons/bits/1k.png');
	}
	if (bits > 4999 && bits <= 9999) {
		return require('@/assets/icons/bits/5k.png');
	}
	if (bits > 9999 && bits <= 99999) {
		return require('@/assets/icons/bits/10k.png');
	}
	if (bits > 99999) {
		return require('@/assets/icons/bits/10k.png');
	}

	return require('@/assets/icons/bits/100.png');
};

const eyePresets = [
	{ name: 'Eyes 1', file: '1.gif' },
	{ name: 'Eyes 2', file: '2.gif' },
	{ name: 'Eyes 3', file: '3.gif' },
	{ name: 'Eyes 4', file: '4.gif' },
	{ name: 'Eyes 5', file: '5.gif' },
	{ name: 'Eyes 6', file: '6.gif' },
	{ name: 'Eyes 7', file: '7.gif' },
	{ name: 'Eyes 8', file: '8.gif' },
	{ name: 'Eyes 9', file: '9.gif' },
	{ name: 'Eyes 10', file: '10.gif' },
	{ name: 'Eyes 11', file: '11.gif' },
	{ name: 'Eyes 12', file: '12.gif' },
	{ name: 'Eyes 13', file: '13.gif' },
	{ name: 'Eyes 14', file: '14.gif' },
	{ name: 'Eyes 15', file: '15.gif' },
	{ name: 'Eyes 16', file: '16.gif' },
	{ name: 'Eyes 17', file: '17.gif' },
	{ name: 'Eyes 18', file: '18.gif' },
	{ name: 'Eyes 19', file: '19.gif' },
	{ name: 'Eyes 20', file: '20.gif' },
];

const mouthPresets = [
	{ name: 'Mouth 1', file: '1.gif' },
	{ name: 'Mouth 2', file: '2.gif' },
	{ name: 'Mouth 3', file: '3.gif' },
	{ name: 'Mouth 4', file: '4.gif' },
	{ name: 'Mouth 5', file: '5.gif' },
	{ name: 'Mouth 6', file: '6.gif' },
	{ name: 'Mouth 7', file: '7.gif' },
	{ name: 'Mouth 8', file: '8.gif' },
	{ name: 'Mouth 9', file: '9.gif' },
	{ name: 'Mouth 10', file: '10.gif' },
	{ name: 'Mouth 11', file: '11.gif' },
	{ name: 'Mouth 12', file: '12.gif' },
	{ name: 'Mouth 13', file: '13.gif' },
	{ name: 'Mouth 14', file: '14.gif' },
	{ name: 'Mouth 15', file: '15.gif' },
	{ name: 'Mouth 16', file: '16.gif' },
	{ name: 'Mouth 17', file: '17.gif' },
	{ name: 'Mouth 18', file: '18.gif' },
	{ name: 'Mouth 19', file: '19.gif' },
	{ name: 'Mouth 20', file: '20.gif' },
];

const LANGUAGES = [
	{ name: 'English', code: 'en' },
	{ name: 'French', code: 'fr' },
	{ name: 'German', code: 'de' },
	{ name: 'Portuguese', code: 'pt' },
	{ name: 'Russian', code: 'ru' },
	{ name: 'Japanese', code: 'ja' },
	{ name: 'Korean', code: 'ko' },
	{ name: 'Italian', code: 'it' },
];

const TTS_ASSETS_BASE =
	'https://twitchstreamstickers.s3.us-east-2.amazonaws.com/extension.streamstickers.com/0.0.1/tts';

const VOICE_SCRIPT = 'https://docs.google.com/document/d/1cEpcRSEtkNAqiKliRHKsgKR6sF_hSdcI_5DxA-WMAEI/edit?usp=sharing';

const parseDate = date => {
	return moment(date, 'YYYY-MM-DD').format('MMM DD, YYYY');
};

export {
	isNumber,
	isArray,
	dataUrlToBlob,
	getBitIcon,
	eyePresets,
	mouthPresets,
	TTS_ASSETS_BASE,
	LANGUAGES,
	VOICE_SCRIPT,
	parseDate,
};
