/**
 * Session initialization.
 */
import store from '@/store';
import axios from '@axios';

const initStreamer = function () {
	const at = localStorage.getItem('accessToken');
	if (at && at.length > 0) {
		axios
			.get(`/init/streamer`)
			.then(res => {
				store.commit('app/SET_MESSAGES', res.data.messages);
			})
			.catch(error => console.log(error));
	}
};

export { initStreamer };
