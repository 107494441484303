<template>
	<component :is="resolveLayoutVariant" :class="[`skin-variant--${appSkinVariant}`, tourShow && 'voverlay']">
		<!-- Display global error messages in a snackbar. -->
		<v-snackbar v-model="showSnackbar" timeout="5000" color="red" top>
			{{ snackbarMessage }}
		</v-snackbar>

		<Tutorial
			v-if="$vuetify.breakpoint.mdAndUp && tutorial && $route.path !== '/login'"
			:show="tutorial"
			@close="closeWelcome" />

		<Tour v-on:closeTour="tourShow = false" />

		<v-overlay v-if="tourShow" v-model="tourShow"></v-overlay>

		<!-- Generic Popup Please place it where it is needed -->
		<GenericPopup v-if="popup && $route.path != '/login'" :modal="popup" @close="popup = false">
			<template #primaryHeader>Patrick Header</template>
			<template #secondaryHeader>Patrick Header</template>
			<template #headerImage><img src="@/assets/images/setting.png" width="200" alt="" /></template>
			<template>
				<p class="fs--13 fw--400 fieldFore--text">
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dapibus sapien mi, vitae pretium
					justo viverra et. Pellentesque libero tortor, lacinia at eros eget, porttitor posuere neque.
				</p>
				<p class="fs--13 fw--400 fieldFore--text">
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dapibus sapien mi, vitae pretium
					justo viverra et. Pellentesque libero tortor, lacinia at eros eget, porttitor posuere neque.
				</p>
			</template>
		</GenericPopup>

		<!-- Non Affiliate Popup Please place it where it is needed -->
		<NonAffiliatePopup v-if="aff_popup && $route.path != '/login'" :modal="aff_popup" @close="aff_popup = false">
			<template>Sorry! You must be Twitch Affiliate level or higher to enable party mode.</template>
		</NonAffiliatePopup>

		<!-- Permission Popup Please place it where it is needed -->
		<PermissionPopup
			v-if="permissionPopup && $route.path != '/login'"
			:modal="permissionPopup"
			@close="permissionPopup = false" />

		<!-- Voice Shared Popup to be displayed when a new voice will be shared with user -->
		<VoiceSharedPopup :modal="voiceShared" @close="voiceShared = false" />

		<transition :name="appRouteTransition" mode="out-in" appear>
			<router-view></router-view>
		</transition>
	</component>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed, onMounted, ref, watch } from '@vue/composition-api';
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig';
import { useRouter } from '@core/utils';
import { useLayout } from '@core/layouts/composable/useLayout';

// Dynamic vh
import useDynamicVh from '@core/utils/useDynamicVh';

// Layouts
import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue';
import LayoutContentHorizontalNav from '@/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue';
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue';

import Tutorial from '@/components/tutorial/Welcome';
import Tour from '@/components/tutorial/Tour';

import GenericPopup from '@/components/GenericPopup';
import NonAffiliatePopup from '@/components/NonAffiliatePopup';
import PermissionPopup from '@/components/PermissionPopup';
import VoiceSharedPopup from '@/components/VoiceSharedPopup';

import store from '@/store';

import AppWebSocket from './utils/ws';
import { initStreamer } from './utils/init';

export default {
	components: {
		LayoutContentVerticalNav,
		LayoutContentHorizontalNav,
		LayoutBlank,
		GenericPopup,
		NonAffiliatePopup,
		Tutorial,
		Tour,
		PermissionPopup,
		VoiceSharedPopup,
	},
	setup(_, context) {
		const popup = ref(false);
		const voiceShared = ref(false);
		const aff_popup = ref(false);
		const permissionPopup = ref(false);
		const tourShow = ref(false);
		const { route, router } = useRouter();
		const { appContentLayoutNav, appSkinVariant, appRouteTransition } = useAppConfig();

		const { handleBreakpointLayoutSwitch } = useLayout();
		handleBreakpointLayoutSwitch();

		const resolveLayoutVariant = computed(() => {
			if (route.value.meta.layout === 'blank') return 'layout-blank';
			if (route.value.meta.layout === 'content') return `layout-content-${appContentLayoutNav.value}-nav`;

			return null;
		});

		useDynamicVh();

		const closeWelcome = () => {
			store.commit('app/SET_TUTORIAL', false);
			tourShow.value = true;
			localStorage.setItem('tutorialComplete', true);

			store.commit('app/SET_STEP', 'stickers');
			router.push('stickers');

			context.root.$tours['myTour'].start();
		};

		// Connect to the server via WSS.
		const appWebSocket = new AppWebSocket();
		appWebSocket.connect();

		onMounted(() => {
			// Init the streamer.
			initStreamer();

			// Run again periodically to refresh the Twitch auth token.
			const initRepeatMinutes = 30;
			setInterval(() => {
				initStreamer();
			}, initRepeatMinutes * 60 * 1000);
		});

		// Check if we have shown the tutorial yet.
		if (!localStorage.getItem('tutorialComplete')) {
			setTimeout(() => {
				store.commit('app/SET_TUTORIAL', true);
			}, 3000);
		}

		const tutorial = computed(() => store.state.app.showTutorial);

		// Global error snackbar.
		const showSnackbar = ref(false);
		const snackbarMessage = ref('');

		// An axios interceptor will set the global error message to the store.
		watch(
			() => store.state.app.globalErrorMessage,
			() => {
				if (store.state.app.globalErrorMessage) {
					showSnackbar.value = false;
					snackbarMessage.value = store.state.app.globalErrorMessage;
					store.commit('app/SET_GLOBAL_ERROR_MESSAGE', null);
					showSnackbar.value = true;
				}
			},
		);

		return {
			voiceShared,
			tutorial,
			tourShow,
			closeWelcome,
			popup,
			aff_popup,
			permissionPopup,
			resolveLayoutVariant,
			appSkinVariant,
			appRouteTransition,
			showSnackbar,
			snackbarMessage,
		};
	},
};
</script>
