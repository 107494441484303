<template>
	<GenericPopup :modal="modal" @close="$emit('close')">
		<template #primaryHeader>Feature Locked</template>
		<template #secondaryHeader>Twitch Affiliate Level Required</template>
		<template #headerImage><img src="@/assets/images/setting.png" width="200" alt="" /></template>
		<template>
			<p class="fs--13 fw--400 fieldFore--text"><slot></slot></p>
			<p class="fs--13 fw--400 fieldFore--text">
				<a href="https://help.twitch.tv/s/article/joining-the-affiliate-program" target="_blank">More Info</a>
			</p>
		</template>
	</GenericPopup>
</template>

<script>
import GenericPopup from '@/components/GenericPopup';
import { mdiClose } from '@mdi/js';
export default {
	props: {
		modal: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		GenericPopup,
	},
	setup() {
		return {
			closeIcon: mdiClose,
		};
	},
};
</script>

<style lang="scss" scoped>
.popup-header {
	position: relative;
	padding: 12px 0;
	background: url('../assets/images/backgrounds/sticker-upload-header.png');
	background-repeat: no-repeat;
	background-size: fill;
}

.close-icon {
	position: absolute;
	top: 15px;
	right: 15px;
}

.content-section {
	width: 90%;
	margin: auto;
}
</style>
