import { getWssEndpoint } from './config';

export default class AppWebSocket {
	constructor() {
		this.eventBus = require('js-event-bus')();
		this.ws = null;
		this.pingIntervalId = -1;
		this.isOpen = false;
		// TODO Reconnect not working on error. Does not seem to trap the error?
		this.autoReconnect = true;
	}

	connect() {
		if (!localStorage.getItem('accessToken')) {
			return;
		}

		try {
			this.ws = new WebSocket(getWssEndpoint() + '/cms/' + localStorage.getItem('accessToken'));
			this.sinkEvents();
			this.startPingTimer();
			this.autoReconnect = true;
			console.debug('Connected to websocket server.');
		} catch (err) {
			console.error(err.message);
		}
	}

	disconnect() {
		this.autoReconnect = false;
		this.stopPingTimer();
		this.ws.close();
	}

	sinkEvents() {
		const self = this;

		self.ws.onmessage = function (event) {
			const payload = JSON.parse(event.data);
			self.eventBus.emit('ws-message', null, payload);
		};

		self.ws.onopen = () => {
			self.isOpen = true;
		};

		self.ws.onclose = () => {
			self.isOpen = false;
			console.debug(`Disconnected from websocket server.`);
			if (self.autoReconnect) {
				setTimeout(function () {
					self.stopPingTimer();
					self.connect(self.callback);
				}, 5000);
			}
		};

		self.ws.onerror = function (event) {
			console.error('WS error occurred');
			console.error(event);
			self.ws.close();
		};
	}

	// Ping message to keep connection open.
	startPingTimer() {
		const self = this;

		self.pingIntervalId = setInterval(() => {
			if (self.isOpen) {
				self.ws.send(JSON.stringify({ method: 'PING' }));
			}
		}, 60000);
	}

	stopPingTimer() {
		const self = this;
		if (self.pingIntervalId != -1) {
			clearInterval(self.pingIntervalId);
			self.pingIntervalId = -1;
		}
	}
}
