const getApiEndpoint = function () {
	return 'https://' + getEndpointHost() + '/cms2';
};

const getWssEndpoint = function () {
	return 'wss://' + getEndpointHost() + '/ws';
};

const getHostedAssetPath = function () {
	return `https://twitchstreamstickers.s3.us-east-2.amazonaws.com/${getEndpointHost()}`;
};

const getGlobalStickerAssetPath = function () {
	return getHostedAssetPath() + '/0.0.1';
};

const getCustomStickerAssetPath = function (channelId) {
	return getHostedAssetPath() + `/custom_stickers/${channelId}`;
};

const getEndpointHost = function () {
	const host = window.location.host;
	if (host.startsWith('localhost')) {
		const isDevEndpoint = true;
		if (isDevEndpoint) {
			return 'extension-dev.streamstickers.com';
		} else {
			return 'icomponent-api.ngrok.io';
		}
	} else if (host.startsWith('dashboard-dev.streamstickers.com')) {
		return 'extension-dev.streamstickers.com';
	} else if (host.startsWith('dashboard.streamstickers.com')) {
		return 'extension.streamstickers.com';
	} else {
		throw new Error('Invalid host.');
	}
};

export {
	getEndpointHost,
	getApiEndpoint,
	getWssEndpoint,
	getHostedAssetPath,
	getGlobalStickerAssetPath,
	getCustomStickerAssetPath,
};
