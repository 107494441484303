<template>
	<v-menu offset-y left nudge-bottom="14" min-width="230" content-class="user-profile-menu-content">
		<template v-slot:activator="{ on, attrs }" v-if="jwtClaims">
			<v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
				<v-avatar size="40px" v-bind="attrs" color="primary" class="v-avatar-light-bg primary--text" v-on="on">
					<v-img :src="jwtClaims.picture"></v-img>
				</v-avatar>
			</v-badge>
		</template>
		<v-list v-if="jwtClaims">
			<div class="pb-3 pt-2">
				<v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
					<v-avatar size="40px" color="primary" class="v-avatar-light-bg primary--text">
						<v-img :src="jwtClaims.picture"></v-img>
					</v-avatar>
				</v-badge>
				<div class="d-inline-flex flex-column justify-center ms-3" style="vertical-align: middle">
					<span class="text--primary font-weight-semibold mb-n1">{{ jwtClaims.displayName }}</span>
					<small class="text--disabled text-capitalize">{{ jwtClaims.role }}</small>
				</div>
			</div>

			<v-divider></v-divider>

			<!-- My Stickers -->
			<v-list-item to="/stickers">
				<v-list-item-avatar size="22" class="me-2">
					<img width="70%" :src="require('@/assets/icons/sidebar/Stickers.png')" alt="My Stickers" />
				</v-list-item-avatar>
				<v-list-item-content>
					<v-list-item-title>My Stickers</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<!-- Settings -->
			<v-list-item to="/settings">
				<v-list-item-icon class="me-2">
					<v-icon size="22" color="white">
						{{ icons.mdiCogOutline }}
					</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>My Settings</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<!-- Channel -->
			<v-list-item :href="`https://twitch.tv/${getUserName()}`" target="_blank">
				<v-list-item-icon class="me-2">
					<v-icon size="22" color="white">
						{{ icons.mdiAccountArrowRightOutline }}
					</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>My Channel</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<!-- Support -->
			<v-list-item href="https://discord.com/invite/2ePbGrc" target="_blank">
				<v-list-item-icon class="me-2">
					<v-icon size="22" color="white">
						{{ icons.mdiChatOutline }}
					</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>Get Support</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<!-- Website -->
			<v-list-item href="https://www.streamstickers.com/" target="_blank">
				<v-list-item-icon class="me-2">
					<v-icon size="22" color="white">
						{{ icons.mdiWeb }}
					</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>Visit Website</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-divider class="my-2"></v-divider>

			<!-- Logout -->
			<v-list-item @click="logoutUser">
				<v-list-item-icon class="me-2">
					<v-icon size="22" color="white">
						{{ icons.mdiLogoutVariant }}
					</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>Logout</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>
	</v-menu>
</template>

<script>
import {
	mdiAccountOutline,
	mdiEmailOutline,
	mdiWeb,
	mdiAccountArrowRightOutline,
	mdiChatOutline,
	mdiCogOutline,
	mdiCurrencyUsd,
	mdiHelpCircleOutline,
	mdiLogoutVariant,
} from '@mdi/js';
import { useRouter } from '@core/utils';
import { getCurrentInstance } from '@vue/composition-api';
import { initialAbility } from '@/plugins/acl/config';
import { getUserName } from '@/utils/auth';
import jwt from 'jsonwebtoken';

export default {
	setup() {
		const vm = getCurrentInstance().proxy;
		const { router } = useRouter();

		// Load user info from JWT.
		// TODO Move this to a plugin - piggyback on the Materio "acl" plugin?
		let jwtClaims;
		if (localStorage.getItem('accessToken')) {
			try {
				jwtClaims = jwt.decode(localStorage.getItem('accessToken'));
			} catch (error) {
				console.error(error);
				localStorage.removeItem('accessToken');
			}
		}

		const logoutUser = () => {
			// Remove userData from localStorage
			// ? We just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
			localStorage.removeItem('accessToken');

			// Remove userData & Ability from localStorage
			localStorage.removeItem('userData');
			localStorage.removeItem('userAbility');

			// Reset ability
			//vm.$ability.update(initialAbility)

			// Redirect to login page
			router.push({ name: 'auth-login' });
		};

		return {
			jwtClaims,
			getUserName,
			logoutUser,
			icons: {
				mdiAccountOutline,
				mdiEmailOutline,
				mdiWeb,
				mdiChatOutline,
				mdiCogOutline,
				mdiCurrencyUsd,
				mdiHelpCircleOutline,
				mdiLogoutVariant,
				mdiAccountArrowRightOutline,
			},
		};
	},
};
</script>

<style lang="scss">
.user-profile-menu-content {
	.v-list-item {
		min-height: 2.5rem !important;
	}
}
</style>
