import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import VueTour from 'vue-tour'
import 'vue-tour/dist/vue-tour.css'
import draggable from 'vuedraggable';
import VueDraggableResizable from 'vue-draggable-resizable'
import drr from '@minogin/vue-drag-resize-rotate'
// import 'vue-draggable-resizable/dist/VueDraggableResizable.css'

import { GTAG_KEY } from './utils/constants'

Vue.component('drr', drr)
Vue.component('draggable', draggable)
Vue.component('vue-draggable-resizable', VueDraggableResizable)
Vue.use(VueTour);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),

  beforeMount() {
    const host = window.location.host;
    let hostType = 'DEV'

    if (host.startsWith('dashboard.streamstickers.com')) {
      hostType = 'PROD'
    }

    const gtagKey = document.createElement('script');
    gtagKey.src = `https://www.googletagmanager.com/gtag/js?id=${GTAG_KEY[hostType]}`;
    gtagKey.async = true;

    document.head.appendChild(gtagKey);

    // Define a callback function to initialize Google Analytics
    window['dataLayer'] = window['dataLayer'] || [];
    function gtag() {
      window['dataLayer'].push(arguments);
    }
    window['gtag'] = gtag;

    gtagKey.onload = () => {
      gtag('js', new Date());
      gtag('config', GTAG_KEY[hostType]);
    };
  }
}).$mount('#app')
