import jwt from 'jsonwebtoken';
import { computed } from '@vue/composition-api';

const getAccessToken = function () {
	return localStorage.getItem('accessToken');
};

const parseAccessToken = function () {
	const token = getAccessToken();
	if (token && token.length > 0) {
		return jwt.decode(token);
	} else {
		return {};
	}
};

const getUserId = function () {
	return parseAccessToken().userId;
};

const getDisplayName = function () {
	return parseAccessToken().displayName;
};

const getUserName = function () {
	return parseAccessToken().displayName.toLowerCase();
};

const getBroadcasterType = function () {
	return parseAccessToken().broadcasterType;
};

// Returns true if the streamer is an affiliate or partner.
const isBitsEnabled = computed(() => {
	const type = getBroadcasterType();
	if (type && type.length > 0) {
		return true;
	} else {
		return false;
	}
});

export { getAccessToken, parseAccessToken, getUserId, getDisplayName, getUserName, isBitsEnabled };
