<template>
	<layout-content-vertical-nav :nav-menu-items="navMenuItems">
		<slot></slot>

		<!-- Slot: Navbar -->
		<template #navbar="{ toggleVerticalNavMenuActive }">
			<div class="navbar-content-container" :class="{ 'expanded-search': shallShowFullSearch }">
				<!-- Left Content: Search -->
				<!-- <div class="d-flex align-center col-10">
					<v-icon v-if="$vuetify.breakpoint.mdAndDown" class="me-3" @click="toggleVerticalNavMenuActive">
						{{ icons.mdiMenu }}
					</v-icon>
					<app-bar-news></app-bar-news>
				</div> -->

				<!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
				<!-- <div class="d-flex align-center right-row">
					<app-bar-user-menu></app-bar-user-menu>
				</div> -->

				<v-row align="center" justify-sm="center" justify-md="start">
					<v-col cols="10" sm="11" md="6" lg="7" offset-md="1" offset-lg="0" class="d-flex px-md-0 px-lg-3">
						<v-icon v-if="$vuetify.breakpoint.smAndDown" class="me-3" @click="toggleVerticalNavMenuActive">
							{{ icons.mdiMenu }}
						</v-icon>
						<app-bar-news></app-bar-news>
					</v-col>
					<v-col cols="5" md="2" class="d-none d-md-block">
						<v-btn
							href="https://discord.gg/2ePbGrc"
							target="_blank"
							color="secondary"
							outlined
							depressed
							block>
							<span class="white--text">Report Bugs</span>
						</v-btn>
					</v-col>
					<v-col cols="5" md="2" class="d-none d-md-block">
						<v-btn
							href="https://ideas.streamstickers.com/b/stream-stickers-ideas/"
							target="_blank"
							color="secondary"
							class="px-md-3"
							outlined
							depressed
							block>
							<span class="white--text">Share Feedback</span>
						</v-btn>
					</v-col>
					<v-col cols="2" sm="1" md="1" lg="1" class="text-right">
						<app-bar-user-menu></app-bar-user-menu>
					</v-col>
				</v-row>
			</div>
		</template>

		<!-- Slot: Footer -->
		<template #footer>
			<div class="d-flex justify-space-between fieldFore--text">
				<span>COPYRIGHT &copy; {{ new Date().getFullYear() }} TurboHype LLC, All Rights Reserved</span>
			</div>
		</template>
	</layout-content-vertical-nav>
</template>

<script>
import LayoutContentVerticalNav from '@/@core/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue';
import navMenuItems from '@/navigation/vertical';

// App Bar Components
import AppBarUserMenu from '@/components/AppBarUserMenu.vue';
import AppBarNews from '@/components/AppBarNews.vue';
import AppBarReport from '@/components/AppBarReport.vue';
import AppBarFeedback from '@/components/AppBarFeedback.vue';

import { mdiMenu, mdiHeartOutline } from '@mdi/js';

import { getVuetify } from '@core/utils';

// Search Data
import appBarSearchData from '@/assets/app-bar-search-data';

import { ref, watch } from '@vue/composition-api';

export default {
	components: {
		LayoutContentVerticalNav,

		// App Bar Components
		AppBarUserMenu,
		AppBarNews,
		AppBarReport,
		AppBarFeedback,
	},
	setup() {
		const $vuetify = getVuetify();

		// Search
		const appBarSearchQuery = ref('');
		const shallShowFullSearch = ref(false);
		const maxItemsInGroup = 5;
		const totalItemsInGroup = ref({
			pages: 0,
			files: 0,
			contacts: 0,
		});
		watch(appBarSearchQuery, () => {
			totalItemsInGroup.value = {
				pages: 0,
				files: 0,
				contacts: 0,
			};
		});

		// NOTE: Update search function according to your usage
		const searchFilterFunc = (item, queryText, itemText) => {
			if (item.header || item.divider) return true;

			const itemGroup = (() => {
				if (item.to !== undefined) return 'pages';
				if (item.size !== undefined) return 'files';
				if (item.email !== undefined) return 'contacts';

				return null;
			})();

			const isMatched = itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;

			if (isMatched) {
				if (itemGroup === 'pages') totalItemsInGroup.value.pages += 1;
				else if (itemGroup === 'files') totalItemsInGroup.value.files += 1;
				else if (itemGroup === 'contacts') totalItemsInGroup.value.contacts += 1;
			}

			return appBarSearchQuery.value && isMatched && totalItemsInGroup.value[itemGroup] <= maxItemsInGroup;
		};

		// ? Handles case where in <lg vertical nav menu is open and search is triggered using hotkey then searchbox is hidden behind vertical nav menu overlaty
		const handleShallShowFullSearchUpdate = (isVerticalNavMenuActive, toggleVerticalNavMenuActive) => {
			if ($vuetify.breakpoint.mdAndDown && isVerticalNavMenuActive) {
				toggleVerticalNavMenuActive();
			}
		};

		return {
			navMenuItems,
			handleShallShowFullSearchUpdate,

			// Search
			appBarSearchQuery,
			shallShowFullSearch,
			appBarSearchData,
			searchFilterFunc,

			icons: {
				mdiMenu,
				mdiHeartOutline,
			},
		};
	},
};
</script>

<style lang="scss" scoped>
.navbar-content-container {
	height: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-grow: 1;
	position: relative;
}

// ? Handle bg of autocomplete for blured appBar
.v-app-bar.bg-blur {
	.expanded-search {
		::v-deep .app-bar-autocomplete-box div[role='combobox'] {
			background-color: transparent;
		}

		> .d-flex > button.v-icon {
			display: none;
		}

		// ===

		& > .right-row {
			visibility: hidden;
			opacity: 0;
		}

		::v-deep .app-bar-search-toggler {
			visibility: hidden;
		}
	}
}

.news {
	width: 100%;
	background: -webkit-linear-gradient(to right, #ff228d, #c64ba9, #7a71cc);
	background: -o-gradient(to right, #ff228d, #c64ba9, #7a71cc);
	background: -ms-gradient(to right, #ff228d, #c64ba9, #7a71cc);
	background: -moz-gradient(to right, #ff228d, #c64ba9, #7a71cc);
	background: linear-gradient(to right, #ff228d, #c64ba9, #7a71cc);
	padding: 8px 10px;
	border-radius: 5px;
}
</style>
