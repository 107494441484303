import {
	mdiAccountOutline,
	mdiChartLine,
	mdiCogOutline,
	mdiBookmarkOutline,
	mdiEmoticonWinkOutline,
	mdiFileOutline,
	mdiMessageTextOutline,
	mdiNewspaperVariantOutline,
} from '@mdi/js';

export default [
	{
		title: 'Dashboard',
		type: 'icon',
		icon: mdiNewspaperVariantOutline,
		to: 'home',
		id: 'home-page',
		step: '',
	},
	{
		title: 'Stickers',
		type: 'image',
		icon: 'Stickers.png',
		to: 'streamer-stickers',
		id: 'stickers-page',
		tag: 'New',
		step: 'stickers',
		badge: 6,
		badgeColor: 'red',
	},
	{
		title: 'TTS Voice Lab',
		type: 'image',
		icon: 'mic.png',
		to: 'streamer-tts-voice-lab',
		id: 'tts-voice-lab-page',
		step: 'tts-voice-lab',
	},
	{
		title: 'Sticker Party',
		type: 'image',
		icon: 'Sticker Party.png',
		to: 'streamer-sticker-party',
		id: 'stickers-party-page',
		step: 'sticker-party',
	},
	{
		title: 'Stick-O-Vision',
		type: 'image',
		icon: 'Stick Vision.png',
		to: 'stick-o-vision',
		id: 'stick-o-vision-page',
		step: 'stick-o-vision',
	},
	{
		title: 'Chat Bot',
		type: 'image',
		icon: 'Chat Bot.png',
		to: 'streamer-chat-bot',
		id: 'chatbot-page',
		step: '',
	},
	{
		title: 'Insights',
		type: 'icon',
		icon: mdiChartLine,
		to: 'streamer-insights',
		id: 'insights-page',
		step: '',
	},
	{
		title: 'Settings',
		type: 'icon',
		icon: mdiCogOutline,
		to: 'streamer-settings',
		id: 'settings-page',
		step: 'settings',
	},
	// {
	// 	title: 'Subscriptions',
	// 	type: 'icon',
	// 	icon: mdiBookmarkOutline,
	// 	to: 'subscriptions',
	// 	id: 'subscriptions-page',
	// 	step: 'subscriptions',
	// },
	// {
	// 	title: 'Tutorial',
	// 	icon: mdiAccountOutline,
	// 	to: 'tutorial',
	// },
];
