<template>
  <v-list-item v-if="canViewVerticalNavMenuLink(item)" v-bind="linkProps" :class="getClasses" :id="item.id"
    class="vertical-nav-menu-link" active-class="bg-gradient-primary">
    <v-tooltip color="purple" v-if="$vuetify.breakpoint.mdOnly" class="ml-5 menu-tooltip" right>
      <template v-slot:activator="{ on, attrs }">
        <v-list-item-avatar class="square-avatar" v-bind="attrs" v-on="on" size="25">
          <v-icon v-if="item.type === 'icon'" :class="{'alternate-icon-small': !item.icon}" color="white">{{ item.icon
          }}</v-icon>

          <img v-if="item.type === 'image'" width="70%" :src="require('@/assets/icons/sidebar/'+item.icon)" :alt="item.title">
        </v-list-item-avatar>
      </template>
      <span class="px-0 fs--14 fw--400">{{ item.title }}</span>
    </v-tooltip>

    <v-list-item-avatar v-else size="25" class="square-avatar">
      <v-icon v-if="item.type === 'icon'" :class="{'alternate-icon-small': !item.icon}" color="white">{{ item.icon }}</v-icon>

        <img v-if="item.type === 'image'" width="70%" :src="require('@/assets/icons/sidebar/'+item.icon)" alt="">
    </v-list-item-avatar>

    <v-list-item-title :class="{'white--text': isActive}" class="white--text">
      <span class="white--text">{{ t(item.title) }}</span>

      <v-chip v-if="item.tag" color="pink" class="px-2 ml-2" rounded small>
					{{ item.tag }}
			</v-chip>
    </v-list-item-title>
    <!-- <v-list-item-action v-if="item.badge && item.step === 'stickers'" class="flex-shrink-0">
      <v-badge :color="item.badgeColor" inline :content="stickerCount">
      </v-badge>
    </v-list-item-action> -->
  </v-list-item>
</template>

<script>
import themeConfig from '@themeConfig'
import { useUtils } from '@core/libs/i18n'
import { useUtils as useAclUtils } from '@core/libs/acl'
import useVerticalNavLink from '@core/layouts/composable/vertical-nav/useVerticalNavLink'
import { computed } from '@vue/composition-api'
import store from '@/store'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, linkProps } = useVerticalNavLink(props.item)
    const { t } = useUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()

    const stickerCount = computed(() => {
      return store.state.slotCount
    })

    const getClasses = computed(() => {
      const step = store.state.app.tutorialStep
      if (step && isActive) {
        if (props.item.step && props.item.step === step) {
          return `${step} active-step bg-gradient-primary white--text disabled`
        } else {
          return 'disabled bg-transparent'
        }
      } else {
        // if (isActive) {
        //   return 'bg-gradient-primary white--text'
        // }
      }

      return ''
    })

    return {
      isActive,
      linkProps,
      stickerCount,
      alternateIcon: themeConfig.menu.groupChildIcon,
      getClasses,
      // i18n
      t,

      // ACL
      canViewVerticalNavMenuLink,
    }
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.vertical-nav-menu-link {
  &.v-list-item--active {
    box-shadow: 0 5px 10px -4px rgba(94, 86, 105, 0.42);
    @include elevationTransition();
  }
}

.bg-transparent {
  background: transparent !important
}

.menu-tooltip {
  background: red !important;
}
</style>
