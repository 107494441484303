<template>
	<div class="vertical-nav-menu-container" @mouseenter="isMouseHovered = true" @mouseleave="isMouseHovered = false">
		<slot name="v-nav-menu-header">
			<vertical-nav-menu-header @close-nav-menu="$emit('close-nav-menu')"></vertical-nav-menu-header>
		</slot>

		<!-- Shadow -->
		<div :class="{ 'd-block': shallShadowBottom }" class="shadow-bottom" />

		<!-- <perfect-scrollbar
			class="ps-nav-menu-items"
			:options="perfectScrollbarOptions"
			@ps-scroll-y="
				evt => {
					shallShadowBottom = evt.srcElement.scrollTop > 0;
				}
			">
			<v-list expand shaped class="vertical-nav-menu-items">
				<component
					:is="resolveNavItemComponent(item)"
					v-for="item in navMenuItems"
					:key="item.subheader || item.title"
					:item="item" />
			</v-list>
		</perfect-scrollbar> -->
		<div class="side-nav-content pt-10">
			<v-list expand shaped class="vertical-nav-menu-items pt-15">
				<component
					:is="resolveNavItemComponent(item)"
					v-for="item in navMenuItems"
					:key="item.subheader || item.title"
					:item="item" />
			</v-list>

			<template v-if="isPromoEnabled && !$vuetify.breakpoint.md">
				<div
					class="tutorial d-flex flex-column align-center justify-center px-3 cursor-pointer"
					@click="openBanner()">
					<img :src="promo.nav.image" :alt="promo.nav.ga.title" />
					<!-- <img src="@/assets/icons/Twitter Logo.png" alt="Twitter">
					<h3 class="fs--19 fw--700 mt-3">
						Follow us
					</h3>
					<p class="fs--13 fw--500 mt-1 text-center">
						Drop us a follow us Twitter for more product updates
					</p> -->
				</div>

				<div class="social ps-5 mt-4 pb-4">
					<a href="http://www.twitch.tv/streamstickers" target="_blank">
						<img src="@/assets/images/Twitch.png" alt="Twitch" class="ml-3 cursor-pointer" />
					</a>
				</div>
			</template>
			<template v-else>
				<div class="text-center mt-5">
					<v-tooltip color="purple" v-if="$vuetify.breakpoint.mdOnly" class="ml-5 menu-tooltip" right>
						<template v-slot:activator="{ on, attrs }">
							<v-icon color="white" v-bind="attrs" v-on="on" no-ripple @click="twitterRedirect">
								{{ twitter }}</v-icon
							>
						</template>
						<span class="px-0 fs--14 fw--400">Twitter</span>
					</v-tooltip>
				</div>
				<div class="text-center mt-5 pb-3">
					<v-tooltip color="purple" v-if="$vuetify.breakpoint.mdOnly" class="ml-5 menu-tooltip" right>
						<template v-slot:activator="{ on, attrs }">
							<a v-bind="attrs" v-on="on" href="http://www.twitch.tv/streamstickers" target="_blank">
								<img src="@/assets/images/Twitch.png" alt="Twitch" class="cursor-pointer" />
							</a>
						</template>
						<span class="px-0 fs--14 fw--400">Twitch</span>
					</v-tooltip>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed, provide, ref } from '@vue/composition-api';
import { mdiTwitter } from '@mdi/js';
import { useRouter } from '@core/utils';
import { saveEvent } from '@/utils/gtag';
import promo from '@/promos/promo.js';

// SFCs
import VerticalNavMenuHeader from '@core/layouts/components/vertical-nav-menu/components/vertical-nav-menu-header/VerticalNavMenuHeader.vue';
import VerticalNavMenuSectionTitle from '@core/layouts/components/vertical-nav-menu/components/vertical-nav-menu-section-title/VerticalNavMenuSectionTitle.vue';
import VerticalNavMenuGroup from '@core/layouts/components/vertical-nav-menu/components/vertical-nav-menu-group/VerticalNavMenuGroup.vue';
import VerticalNavMenuLink from '@core/layouts/components/vertical-nav-menu/components/vertical-nav-menu-link/VerticalNavMenuLink.vue';

// Composable
import useVerticalNavMenu from '@/@core/layouts/composable/vertical-nav/useVerticalNavMenu';

// 3rd Party
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';

export default {
	components: {
		VerticalNavMenuHeader,
		VerticalNavMenuGroup,
		VerticalNavMenuLink,
		VerticalNavMenuSectionTitle,

		// 3rd Party
		PerfectScrollbar,
	},
	props: {
		navMenuItems: {
			type: Array,
			required: true,
		},
	},
	setup() {
		const { route } = useRouter();
		const { resolveNavItemComponent, isMouseHovered } = useVerticalNavMenu();
		provide('isMouseHovered', isMouseHovered);

		const perfectScrollbarOptions = {
			maxScrollbarLength: 60,
			wheelPropagation: false,
		};

		// Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
		const shallShadowBottom = ref(false);

		provide('openGroups', ref([]));

		const twitterRedirect = () => {
			window.open('https://twitter.com/StreamStickers', '_blank');
		};

		const isPromoEnabled = computed(() => {
			return promo && promo.nav && promo.nav.isEnabled;
		});

		const openBanner = url => {
			const ga = promo.nav.ga;
			saveEvent({
				event: 'campaign_banner_click',
				params: {
					event_category: ga.event_category,
					event_label: ga.event_label,
					title: ga.title,
					unit: ga.unit,
					location: route.value.fullPath,
				},
			});
			window.open(promo.nav.url);
		};

		return {
			resolveNavItemComponent,
			perfectScrollbarOptions,
			isMouseHovered,
			shallShadowBottom,
			twitterRedirect,
			isPromoEnabled,
			promo,
			openBanner,
			twitter: mdiTwitter,
		};
	},
};
</script>

<style lang="scss">
@import '~vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
</style>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';
@import '~@core/preset/preset/mixins.scss';

.app-navigation-menu {
	transition-duration: 0.4s !important;
	transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1), ease !important;
	will-change: box-shadow, transform !important;

	.v-list-item,
	.v-subheader {
		padding: 0 18px 0 22px;
	}

	.v-list-item {
		margin-top: 0.375rem;
	}

	.v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon {
		min-width: 24px;
	}

	.v-navigation-drawer__border {
		background-color: transparent !important;
	}

	// &.v-navigation-drawer--open-on-hover.v-navigation-drawer--is-mouseover {
	//   @include elevation(9);
	//   @include elevationTransition();
	// }
}

@include app-elevation('app-navigation-menu.v-navigation-drawer--open-on-hover.v-navigation-drawer--is-mouseover', 9);

// Set Perfect Scrollbar Container Height
.vertical-nav-menu-container {
	height: 100%;
	background-image: linear-gradient(to bottom, #7410dd, #6a36e1, #586fe8, #4a99ee, #36d4f6);

	.ps-nav-menu-items {
		height: calc(100% - 57px) !important;
	}

	// menu scroll shadow
	.shadow-bottom {
		display: none;
		position: absolute;
		z-index: 2;
		height: 50px;
		width: 100%;
		left: -6px;
		pointer-events: none;
		margin-top: -0.7rem;
		filter: blur(5px);
	}
}

@include theme--child(vertical-nav-menu-container) using ($material) {
	.shadow-bottom {
		background: linear-gradient(
			map-deep-get($material, 'background') 40%,
			rgba(map-deep-get($material, 'background'), 0.1) 95%,
			rgba(map-deep-get($material, 'background'), 0.05)
		);
		margin-top: -0.5rem;
		filter: blur(4px);
	}
}

@include theme(vertical-nav-menu-items) using ($material) {
	@include ltr() {
		padding-right: 20px !important;
	}

	@include rtl() {
		padding-left: 20px !important;
	}

	@at-root .app-navigation-menu.v-navigation-drawer--mini-variant:not(.v-navigation-drawer--is-mouseover) {
		.vertical-nav-menu-items {
			@include ltr() {
				padding-right: 5px !important;
			}

			@include rtl() {
				padding-left: 5px !important;
			}
		}
	}

	.v-subheader:not(:first-child) {
		margin-top: 18px;
	}

	.v-list-item__icon {
		&:first-child {
			@include ltr() {
				margin: 0 12px 0 0;
			}

			@include rtl() {
				margin: 0 0 0 12px;
			}

			align-self: center;
		}

		&.v-list-group__header__append-icon {
			@include ltr() {
				margin-left: 4px;
			}

			@include rtl() {
				margin-right: 4px;
			}
		}
	}

	// Center align small icon
	.v-list-group__items .v-list-item__icon {
		margin-top: 0px;

		@include ltr() {
			margin-left: 5px;
		}

		@include rtl() {
			margin-right: 5px;
		}
	}

	.v-list-item {
		@include app-states($material);

		height: 44px;
		min-height: 44px;
		color: map-deep-get($material, 'text', 'primary');
		// ? Reason: In collapsed mode due to modified mini width & flex grow icon change position while collapsing the drawer
		justify-content: flex-start !important;

		&.v-list-item--active {
			&:not(.v-list-group__header) {
				color: white;
				// background-image: linear-gradient(98deg, #c48eff, var(--v-primary-base) 94%);
			}

			&.v-list-group__header {
				background-color: rgba(
					map-deep-get($material, 'text', 'primary'),
					map-deep-get($material, 'states', 'selected')
				);
			}
		}

		&.vertical-nav-menu-link {
			.v-list-item__icon {
				.v-icon {
					transition: none !important;
				}
			}
		}
	}
}

.tutorial {
	width: 200px;
	height: 300px;
	border-radius: 6px;
	color: #a4edff;
	width: 100%;
	margin: auto;
}
</style>
