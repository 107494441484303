const StickerType = {
	CUSTOM: 'CUSTOM',
	REWARD: 'REWARD',
	POWER: 'POWER',
	GLOBAL: 'GLOBAL',
	TTS: 'TTS',
	GLOBAL_AI_TTS: 'GLOBAL_AI_TTS',
};

const StickerDefaults = {
	SKU: 'ticket_100',
	STICKINESS: 71,
	VOLUME: 70,
	IS_STICKER_PLACEMENT_ENABLED: false,
};

const TtsStickerDisplayMode = {
	STICKER: 'STICKER',
	CO_HOST: 'CO_HOST',
};

const GlobalTtsDisplaySize = {
	REGULAR: 'REGULAR',
	LARGE: 'LARGE',
};

const TtsApprovalQueueStatus = {
	NEW: 'NEW',
	APPROVED: 'APPROVED',
	REJECTED: 'REJECTED',
	EXPIRED: 'EXPIRED',
};

const TtsAudioSource = {
	AMAZON: 'AMAZON',
	ELEVEN_LABS: 'ELEVEN_LABS',
};

const VoiceLabUploadType = {
	RECORD: 'RECORD',
	MANUAL: 'MANUAL',
};

const VoiceLabOwnership = {
	GLOBAL: 'GLOBAL', // Owned by the platform
	SHARED_TO: 'SHARED_TO', // Owned by another streamer, but shared to this streamer
	STREAMER: 'STREAMER', // Owned by this streamer
};

const ChatBotDefaults = {
	ENABLED: false,
	LINES: 10,
	MINUTES: 5,
	COOLDOWN: 6,
};

const MaxFileSizes = {
	IMAGE: 2000000,
	AUDIO: 1500000,
};

const DefaultTtsVoiceId =
	'7b0a202022696422203a2022427269616e222c0a202022617564696f536f7572636522203a2022414d415a4f4e220a7d';

const VoiceType = {
	AI: 'ai',
	STANDARD: 'standard',
};

const VoiceDefaults = {
	STABILITY: 30,
	SIMILARITY: 80,
	STYLE: 0,
};

const MessageEnum = {
	PING: 'PING',
	ACK: 'ACK',
	BITS_TRANSACTION: 'BITS_TRANSACTION',
	EXTENSION_STATUS: 'EXTENSION_STATUS',
	EXTENSION_CO_HOST_STATUS: 'EXTENSION_CO_HOST_STATUS',
	PARTY_MODE_START: 'PARTY_MODE_START',
	STICKER_OPEN_SLOT_CHANGE: 'STICKER_OPEN_SLOT_CHANGE',
	TTS_APPROVAL_EXPIRED: 'TTS_APPROVAL_EXPIRED',
	TTS_PENDING_APPROVAL: 'TTS_PENDING_APPROVAL',
	USER_CONFIG_CHANGE: 'USER_CONFIG_CHANGE',
};

const StickerQuickAdjustField = {
	STICKINESS: 'STICKINESS',
	VOLUME: 'VOLUME',
	SKU: 'SKU',
	CUSTOM_PLACEMENT: 'CUSTOM_PLACEMENT',
};

const GTAG_KEY = {
	DEV: 'G-WDVRRRHSF7',
	PROD: 'G-3DNPFCJMTV',
};

const textArray = [
	`We're stepping into a world where the laws of reality took a vacation and the pink flamingos are running the show.`,
	`Strap in, my friends, because today we're diving into a game so absurdly awesome that it makes pineapple on pizza seem normal!`,
	`Grab your lucky rubber chicken, because we're about to embark on a quest that involves dancing goblins and disco-loving dragons!`,
	`Can you feel the excitement in the air? Or is it just the scent of victory-flavored popcorn?`,
	`If you're ever faced off with a fierce enemy, just offer them a taco. Works like a charm, trust me!`,
	`You know you're in for a rollercoaster of emotions when even the in-game squirrels are questioning their life choices!`,
	`We're about to venture into a world where the laws of physics took a vacation and probability went on a coffee break!`,
	`Hold onto your seat cushions, because today we're playing a game so electrifying, it could charge your phone just by looking at it!`,
	`It’s time to unravel the mysteries of a dimension where llamas moonwalk and tea parties are mandatory boss battles!`,
	`I've discovered a cheat code that turns avocados into laser-shooting unicorns. You're welcome!`,
	`Today, we're leveling up our imagination stats and defeating dragons with a potent combination of puns and glitter cannons.`,
	`Remember that moment when you realized you could ride a giant rubber duck while wielding a baguette? Well, that moment is now, and it's going to be legendary.`,
	`Have you ever seen a potato engage in parkour? Well, today's the day! Join me as we navigate a realm where spuds are the true kings of acrobatics.`,
	`If a villain offers you tea and cookies, don't be fooled. It's probably a trap, but hey, at least you'll have snacks!`,
	`I’m here to chew bubblegum and give hugs… and I’m all out of bubblegum! Bring it in!`,
];

const nameFromStickerType = stickerType => {
	if (stickerType == StickerType.CUSTOM) {
		return 'Custom';
	} else if (stickerType == StickerType.REWARD) {
		return 'Reward';
	} else if (stickerType == StickerType.POWER) {
		return 'Power';
	} else if (stickerType == StickerType.GLOBAL) {
		return 'Global';
	} else if (stickerType == StickerType.TTS) {
		return 'TTS';
	}
};

const maxVoiceShares = 25;

const MIX_PANEL_KEYS = {
	PROD: '0b60e2ad5d835900550eb1e66676f3c0',
	DEV: '147f9950eea1eb619903ab5c802cec0f',
};

export {
	StickerType,
	StickerDefaults,
	TtsStickerDisplayMode,
	GlobalTtsDisplaySize,
	TtsApprovalQueueStatus,
	TtsAudioSource,
	VoiceLabUploadType,
	VoiceLabOwnership,
	ChatBotDefaults,
	MaxFileSizes,
	DefaultTtsVoiceId,
	VoiceType,
	VoiceDefaults,
	MessageEnum,
	StickerQuickAdjustField,
	GTAG_KEY,
	nameFromStickerType,
	maxVoiceShares,
	textArray,
	MIX_PANEL_KEYS,
};
