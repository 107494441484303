<template>
    <v-dialog max-width="600" v-model="modal">
        <v-card>
            <div class="popup-header">
                <h3 class="text-capitalize text-center d-none d-sm-block fs--22 fw--500">
                    Subscription Permissions
                </h3>

                <h3 class="text-capitalize text-center d-block d-sm-none fs--18 fw--500">
                    Subscription Permissions
                </h3>

                <v-icon class="close-icon cursor-pointer" @click="closePopup">{{ closeIcon }}</v-icon>
            </div>
            <v-card-text>
                <div class="content-section text-center mt-3 mb-3">
                    <h3 class="fs--18 fw--400 mb-4">In order to enable sub-only stickers, this extension will first need
                        the ability to view your subscription list.</h3>
                    <div class="permission-steps text-left">
                        <h4 class="fw--400 fs--14">STEP 1:</h4>
                        <p class="fs--13 fw--400 fieldFore--text">
                            Open the <span class="pink--text text-underline cursor-pointer">permissions page</span>,
                            find the "Stream Stickers" Extension and toggle the subscriptions permissions to <span
                                class="pink--text fw--600">enabled</span>.
                        </p>
                    </div>

                    <img width="100%" src="@/assets/images/misc/Permissions.png" alt="Permissions">
                    <div class="permission-steps text-left mt-2">
                        <h4 class="fw--400 fs--14">STEP 2:</h4>
                        <p class="fs--13 fw--400 fieldFore--text">
                            Close any Twitch browser windows or tabs and re-open them to refresh your session.
                        </p>
                    </div>
                    <v-btn color="pink" class="px-15 mt-3" depressed large @click="openPage">OK! Open the permissions
                        page!</v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mdiClose } from '@mdi/js';
export default {
    props: {
        modal: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const openPage = () => {
            const userData = JSON.parse(localStorage.getItem('userData'))
            if (userData) {
                const link = `https://dashboard.twitch.tv/u/${userData.username}/extensions/permissions`

                window.open(link, '_blank')
            }
        }
        const closePopup = () => {
            emit('close', true)
        }
        return {
            closeIcon: mdiClose,
            openPage,
            closePopup
        }
    }
}
</script>

<style lang="scss" scoped>
.popup-header {
    position: relative;
    padding: 12px 0;
    background: url('../assets/images/backgrounds/sticker-upload-header.png');
    background-repeat: no-repeat;
    background-size: fill;
}

.close-icon {
    position: absolute;
    top: 15px;
    right: 15px;
}

.content-section {
    width: 90%;
    margin: auto;

    img {
        margin-left: -60px;
    }
}

.permission-steps {
    width: 90%;
    margin: auto;
}
</style>