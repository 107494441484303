<template>
    <v-tour v-if="$vuetify.breakpoint.mdAndUp" name="myTour" :steps="steps">
        <template slot-scope="tour">
            <transition key="step" name="fade">
                <template v-for="(step, index) of tour.steps">
                    <v-step v-if="tour.currentStep === index" :key="index" :step="step"
                        :previous-step="tour.previousStep" :next-step="tour.nextStep" :stop="tour.stop"
                        :is-first="tour.isFirst" :class="addStepClass(step)" :is-last="tour.isLast"
                        :labels="tour.labels">
                        <template v-if="step.target === '#stickers-page'">
                            <div slot="content" class="stickers">
                                <v-row class="px-3">
                                    <v-col cols="6" class="text-center">
                                        <h3 class="fs--26 fw--400">Manage Stickers</h3>
                                        <p class="fieldFore--text fs--14 fw--400 mt-2">
                                            Upload and manage your entire catalog of Custom, TTS, Reward, and Global Stickers.
                                        </p>

                                        <v-btn color="pink" block depressed @click="nextStep('tts-voice-lab')">Next
                                        </v-btn>

                                        <div class="skip-section mt-3">
                                            <span
                                                class="fieldFore--text fs--12 fw-400 text-underline skip-tutorial cursor-pointer"
                                                @click="finishTour">Skip tutorial</span>
                                        </div>
                                    </v-col>
                                </v-row>

                                <img class="d-none d-md-block stickers" src="@/assets/images/misc/tutorial/stickers.png" alt="Stickers" />
                                <img class="d-none d-md-block character" src="@/assets/images/misc/tutorial/character.png" width="50%" alt="Character" />
                            </div>
                            <div slot="actions">
                            </div>
                        </template>
                        <template v-if="step.target === '#tts-voice-lab-page'">
                            <div slot="content" class="tts-voice-lab">
                                <v-row class="px-3">
                                    <v-col cols="6" class="text-center">
                                        <h3 class="fs--26 fw--400">Clone your Voice!</h3>
                                        <p class="fieldFore--text fs--14 fw--400 mt-2 mb-0">
                                            Turn your voice into your own ultra realistic <span class="blueWaves--text">A.I. text to speech (TTS)</span> in less than 5 minutes!
                                        </p>
                                        <p class="fieldFore--text fs--14 fw--400 mt-1">
                                            You can even share your voice with Friends!
                                        </p>
                                        <v-btn color="pink" class="px-15" depressed @click="nextStep('sticker-party')">Next
                                        </v-btn>

                                        <div class="skip-section mt-3">
                                            <span
                                                class="fieldFore--text fs--12 fw-400 text-underline skip-tutorial cursor-pointer"
                                                @click="finishTour">Skip tutorial</span>
                                        </div>
                                    </v-col>
                                </v-row>

                                <img class="d-none d-md-block character" src="@/assets/images/mascot/voice-lab-sm.png" alt="Voice Lab" />
                            </div>
                            <div slot="actions">
                            </div>
                        </template>
                        <template v-if="step.target === '#stickers-party-page'">
                            <div slot="content" class="sticker-party">
                                <v-row class="px-3">
                                    <v-col cols="6" lg="5" class="text-center">
                                        <h3 class="fs--26 fw--400">Start a Party!</h3>
                                        <p class="fieldFore--text fs--14 fw--400 mt-2">
                                            Drive more viewer engagement by activating Sticker Party mode!
                                        </p>

                                        <v-btn color="pink" block depressed @click="nextStep('stick-o-vision')">Next</v-btn>
                                        <div class="skip-section mt-3">
                                            <span
                                                class="fieldFore--text fs--12 fw-400 text-underline skip-tutorial cursor-pointer"
                                                @click="previous('stickers')">Previous</span>
                                        </div>
                                    </v-col>
                                </v-row>
                                <img class="d-none d-md-block" src="@/assets/images/misc/tutorial/partyIcon.png"
                                    width="86%" height="147%" alt="Sticker Party" />
                            </div>
                            <div slot="actions">
                            </div>
                        </template>
                        <template v-if="step.target === '#stick-o-vision-page'">
                            <div slot="content" class="stick-o-vision">
                                <v-row class="px-3 pb-0">
                                    <v-col cols="6" class="text-center">
                                        <h3 class="fs--26 fw--400">Enable Stick-O-Vision!</h3>
                                        <p class="fieldFore--text fs--14 fw--400 mt-2 mb-0">
                                            Grab our optional companion PC application to enable Stick-O-Vision on your desktop monitor!
                                        </p>
                                        <p class="fieldFore--text fs--14 fw--400 mt-2">
                                            With Stick-O-Vision enabled, you'll be able to see all the stickers directly on your monitor as you stream!
                                        </p>
                                        <v-btn color="pink" class="px-15" depressed @click="nextStep('settings')">Next
                                        </v-btn>

                                        <div class="skip-section mt-3">
                                            <span
                                                class="fieldFore--text fs--12 fw-400 text-underline skip-tutorial cursor-pointer"
                                                @click="finishTour">Skip tutorial</span>
                                        </div>
                                    </v-col>
                                </v-row>

                                <img class="d-none d-md-block character" height="114%" src="@/assets/images/mascot/Stick-O-Vision-Tutorial.png" alt="Stick-O-Vision" />
                            </div>
                            <div slot="actions">
                            </div>
                        </template>
                        <template v-if="step.target === '#settings-page'">
                            <div slot="content" class="settings">
                                <v-row class="px-3">
                                    <v-col cols="6" lg="5" class="text-center">
                                        <h3 class="fs--26 fw--400">Manage Preferences</h3>
                                        <p class="fieldFore--text fs--14 fw--400 mt-2">
                                            Manage your personal preferences such as Sticker Size, Placement, Chat Alerts, and more.
                                        </p>

                                        <v-btn color="pink" block depressed @click="finishTour">Finish</v-btn>
                                        <div class="skip-section mt-3">
                                            <span
                                                class="fieldFore--text fs--12 fw-400 text-underline skip-tutorial cursor-pointer"
                                                @click="previous('stick-o-vision')">Previous</span>
                                        </div>
                                    </v-col>
                                    <v-col cols="6" lg="7" class="">
                                        <div class="features text-left px-3 py-3">
                                            <div class="d-flex align-center">
                                                <v-icon color="pink" size="30">{{ right }}</v-icon> <span
                                                    class="fs--16 fw-400">Sticker
                                                    Size</span>
                                            </div>
                                            <div class="d-flex align-center">
                                                <v-icon color="pink" size="30">{{ right }}</v-icon> <span
                                                    class="fs--16 fw-400">Sticker
                                                    Placement</span>
                                            </div>
                                            <div class="d-flex align-center">
                                                <v-icon color="pink" size="30">{{ right }}</v-icon> <span
                                                    class="fs--16 fw-400">Chat
                                                    Alerts</span>
                                            </div>
                                            <div class="d-flex align-center">
                                                <v-icon color="pink" size="30">{{ right }}</v-icon> <span
                                                    class="fs--16 fw-400">Overlay
                                                    Alerts</span>
                                            </div>
                                            <div class="d-flex align-center">
                                                <v-icon color="pink" size="30">{{ right }}</v-icon> <span
                                                    class="fs--16 fw-400">Master
                                                    Volume</span>
                                            </div>

                                            <div class="mt-2 ml-8">
                                                <span class="fs--14 fw-400">and more...</span>
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                                <img class="d-none d-md-block" src="@/assets/images/misc/tutorial/settings-bot.png"
                                    width="50%" alt="Settings Bot" />
                            </div>
                            <div slot="actions">
                            </div>
                        </template>
                    </v-step>
                </template>
            </transition>
        </template>
    </v-tour>
</template>

<script>
import store from '@/store'
import { useRouter } from '@core/utils'
import { getCurrentInstance } from '@vue/composition-api';
import { mdiMenuRight } from '@mdi/js';
import { saveEvent } from '@/utils/gtag';

export default {
    setup(_, { emit }) {
        const vm = getCurrentInstance().proxy
        const { router } = useRouter();
        const steps = [
            {
                target: '#stickers-page',
                params: {
                    placement: 'right'
                }
            },
            {
                target: '#tts-voice-lab-page',
                params: {
                    placement: 'right'
                }
            },
            {
                target: '#stickers-party-page',
                params: {
                    placement: 'right'
                }
            },
            {
                target: '#stick-o-vision-page',
                params: {
                    placement: 'right'
                }
            },
            {
                target: '#settings-page',
                params: {
                    placement: 'right'
                }
            }
        ]

        const addStepClass = (step) => {
            if (step.target === '#stickers-page') return 'stickers-step'
            if (step.target === '#tts-voice-lab-page') return 'tts-voice-lab-step'
            if (step.target === '#stickers-party-page') return 'party-step'
            if (step.target === '#stick-o-vision-page') return 'stick-o-vision-step'
            if (step.target === '#settings-page') return 'setting-step'
        }

        const nextStep = (step) => {
            store.commit('app/SET_STEP', step)
            router.push('/' + step)
            vm.$tours['myTour'].nextStep()
        }

        const previous = (step) => {
            store.commit('app/SET_STEP', step)
            router.go(-1)
            vm.$tours['myTour'].previousStep()
        }

        const finishTour = () => {
            store.commit('app/SET_STEP', null)
            emit('closeTour', false)
            vm.$tours['myTour'].finish()
            router.push('/home')

            // Save Event
            saveEvent({
                event: 'tutorial_complete',
                params: {
                    event_category: 'engagement',
                    event_label: 'User Completes the Tutorial'
                }
            })
        }

        return {
            steps,
            addStepClass,
            nextStep,
            previous,
            finishTour,
            right: mdiMenuRight
        }
    }
}
</script>