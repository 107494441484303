// axios
import axios from 'axios';
import Vue from 'vue';
import store from '@/store';
import { getApiEndpoint } from '../utils/config';

const axiosIns = axios.create({
	// You can add your headers here
	// ================================
	// baseURL: 'https://some-domain.com/api/',
	// timeout: 1000,
	// headers: {'X-Custom-Header': 'foobar'}
	baseURL: getApiEndpoint(),
});

// Axios requests.
axiosIns.interceptors.request.use(
	config => {
		// Set access token.
		const accessToken = localStorage.getItem('accessToken');
		if (accessToken) {
			config.headers.Authorization = `Bearer ${accessToken}`;
		}

		return config;
	},
	error => {
		Promise.reject(error);
	},
);

// Axios responses.
axiosIns.interceptors.response.use(
	response => {
		// If the server sends a JWS with new expiration, store it.
		const jws = response.headers['x-jws'];
		if (jws) {
			localStorage.setItem('accessToken', jws);
		}
		return response;
	},
	error => {
		if (error.response.status === 401) {
			// Trap for server unauthorized, and redirect to login page.
			localStorage.removeItem('accessToken');
			window.location.replace('/login');
		} else if (error.response && error.response.data && error.response.data.error) {
			// If the server responds with error JSON, store the global message for display.
			store.commit('app/SET_GLOBAL_ERROR_MESSAGE', error.response.data.error);
			throw new Error(error.response.data.error);
		} else {
			throw error;
		}
	},
);

Vue.prototype.$http = axiosIns;

export default axiosIns;
