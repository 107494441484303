<template>
	<div>
		<v-snackbar v-model="copied" :timeout="2000" top color="purple"> Link Copied Successfully. </v-snackbar>
		<h4 class="fw--400 text-center">Enable the extension (this will only take a moment)</h4>
		<v-row no-gutters justify="center" class="mt-4 px-3">
			<v-col cols="9" sm="9" lg="6" class="pr-3">
				<v-text-field
					v-model="browserSourceLink"
					disabled
					background-color="purple"
					color="fieldFore"
					dense
					flat
					solo
					hide-details></v-text-field>
			</v-col>
			<v-col cols="3" sm="3" lg="2">
				<v-btn depressed color="primary" block @click="actions.copy">Copy</v-btn>
			</v-col>
			<v-col cols="12" sm="9" md="10" lg="9" class="mt-4">
				<p class="fieldFore--text fs--12 fw--400 text-center">
					In order to use this extension, you'll first need to add a
					<span class="pink--text">Browser Source</span> with the following URL on the
					<span class="pink--text">TOP LAYER</span> of <span class="pink--text">ALL SCENES</span> in your
					broadcaster software such as OBS, Xsplit, etc)
				</p>
			</v-col>
		</v-row>

		<div class="footer-section text-center mt-5">
			<v-btn class="my-6 px-12" depressed @click="actions.moveNext" color="pink">Next</v-btn>
		</div>
	</div>
</template>

<script>
import { ref } from '@vue/composition-api';
import { getEndpointHost } from '../../utils/config';
import { getUserId } from '../../utils/auth';

export default {
	setup(props, { emit }) {
		const browserSourceLink = `https://${getEndpointHost()}:8081/stickers/${getUserId()}/mt`;
		const copied = ref(false);

		const actions = {
			moveNext: () => {
				emit('move', 2);
			},

			// Copy the OBS URL to the clipboard.
			copy: () => {
				navigator.clipboard.writeText(browserSourceLink).then(() => {
					copied.value = true;
					setTimeout(() => {
						copied.value = false;
					}, 2000);
				});
			},
		};

		return {
			actions,
			browserSourceLink,
			copied,
		};
	},
};
</script>
