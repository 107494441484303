<template>
    <div>
        <h4 class="fw--400 text-center">Test your setup</h4>

        <TestSetup class="pl-md-7" :tutorial="true" />
        <div class="footer-section d-flex flex-wrap justify-space-between align-center mt-5 px-5">
            <v-btn depressed color="primary" @click="back"><v-icon>{{ iconLeft }}</v-icon> Back</v-btn>
            <v-btn class="my-6 px-lg-12" depressed @click="finish" color="pink">Finished</v-btn>
            <a class="fieldFore--text fs--12" href=" https://discord.com/invite/2ePbGrc" target="_blank">Not working? Get help here!</a>
        </div>
    </div>
</template>

<script>
import TestSetup from '@/views/streamer/settings/TestSetup'
import { mdiChevronLeft } from '@mdi/js'
export default {
    components: { TestSetup },
    setup(props, { emit }) {

        const finish = () => {
            emit('finish')
        }

        const back = () => {
            emit('back')
        }

        return {
            finish,
            back,
            iconLeft: mdiChevronLeft
        }
    }
}
</script>