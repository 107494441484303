<template>
	<div v-if="messages.length > 0" class="news d-none d-sm-flex align-center">
		<img src="@/assets/images/notification_bell.png" alt="Bell" class="mr-2" />
		<strong class="text-sm">News: </strong>
		<span class="pl-2 fs--14 fw--500 text-xs">{{ messages[0] }}</span>
	</div>
</template>
<script>
import axios from '@axios';
import { reactive } from '@vue/composition-api';
import { mdiBellOutline } from '@mdi/js';

export default {
	setup() {
		const messages = reactive([]);

		const actions = {
			fetchData: function () {
				axios.get('/messages').then(res => {
					messages.length = 0;
					messages.push(...res.data);
				});
			},
		};

		actions.fetchData();

		return {
			messages,
			icons: {
				mdiBellOutline,
			},
		};
	},
};
</script>

<style lang="scss" scoped>
.app-bar-news {
	width: 100%;
	border-radius: 20px;

	// TODO Move to a global.
	.transparent-card {
		background-color: transparent;
	}

	.news-card {
		width: 100%;
		.news-button {
			width: 100%;
			background-image: linear-gradient(to right, #e8318e, #6f78d3);
		}
	}

	.action-button {
		border-color: #af95f4;
	}
}
</style>
